// Sidebox modal styles

.modalCover {
  position: fixed;
  background-color: rgba(12, 12, 12, 0.3);
  z-index: 900;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  animation: modalCoverAni 0.5s;
  animation-direction: normal;
}

.modalCoverClose {
  animation: modalCoverAniClose 0.5s;
  animation-direction: normal;
  opacity: 0;
}

.sideBox {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  width: 65vw;
  height: 100vh;
  overflow: scroll;
  animation: show 0.5s;
  animation-direction: normal;
}

.modalClose {
  animation: close 0.5s;
  animation-direction: normal;
  right: -100vw;
}

.backBtn {
  margin-left: -1rem;
  cursor: pointer;
}

// Content
.mainContainer {
  position: absolute;
  height: 100%;
  background-color: #fff;
  width: 100%;
  box-shadow: 2px 2px 9px #858585;
  padding: 1rem 2.4rem;
  overflow: scroll;
}

.shelfTitle {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2rem !important;
}

@media screen and (min-width: 640px) {
  .mainContainer {
    flex-direction: row;
  }
}

@keyframes show {
  0% {
    opacity: 0.7;
    right: -50vw;
  }
  100% {
    opacity: 1;
    right: 0px;
  }
}

@keyframes close {
  0% {
    opacity: 1;
    right: 0px;
  }
  100% {
    opacity: 0;
    right: -50vw;
  }
}

@keyframes modalCoverAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modalCoverAniClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.function {
  width: 50%;
  padding-left: 2em;
}

// Button get
.button {
  background-color: #315e8f;
  color: #fff;
  padding: 1rem 1.6rem;
  border-radius: 2px;
}

.modalCoverStyles {
  display: flex;
}

.orderInfoWrap {
  width: 50%;
  padding-left: 50px;
}

.shelfTitle {
  text-transform: none;
  font-weight: 600;
  font-size: 1.4rem;
}

.lineList {
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 450px;
  overflow-y: scroll;
}

.lineListItem {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  transition: all 0.3s;
}

.lineListItem:hover {
  text-decoration: underline;
  transition: all 0.3s;
}

.lineWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  transition: all 0.3s;
  cursor: pointer;
}

.lineWrap:hover {
  text-decoration: underline;
  transition: all 0.3s;
  background-color: #f5f5f5;
}

.lineWrap.active {
  text-decoration: underline;
  transition: all 0.3s;
  background-color: #f5f5f5;
}

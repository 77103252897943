.infoWrap {
  padding: 0 1rem;
}

.title {
  font-size: 2rem;
  font-weight: 600;
}

.subtitle {
  font-weight: 500;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  gap: 0.6rem;
  padding: 1rem;
  color: white;
  font-size: 1.8rem;
  border-radius: 4px;
  background-color: #00448d;
  transition: all 0.1s ease-in;
  text-align: center;
  cursor: pointer;
  box-shadow: 2px 2px 4px #c6c1c1;
  &:hover {
    box-shadow: 2px 2px 9px #a2a2a2;
  }
}

.whInfo {
  background-color: #fff;
  padding: 1.6rem;
  border-radius: 4px;
  width: 100%;

  .title {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }

  .em {
    display: inline;
    font-weight: 600;
  }
}

.lineInfo {
  background-color: #fff;
  padding: 1.6rem;
  border-radius: 4px;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;

  .title {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }

  .em {
    display: block;
    font-weight: 600;
  }
}

.lineInfo:hover {
  transition: 0.3s;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
}

.infoFragWrap {
  margin: 0 0 0 -2rem;
  padding: 0 0 0 2rem;
}

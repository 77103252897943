.mapComp {
  border-radius: 4px;
  box-shadow: 2px 2px 4px #c1bfbf;
  height: auto;
  height: fit-content;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  cursor: default;
}

.input {
  padding: 6px 10px;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  width: 75px;

  &:focus {
    border: 1px solid #797979;
  }
}

.subHeading {
  font-size: 2.5rem;
  font-weight: 600;
  cursor: default;
}

// Map define section

.mapContainer {
  max-width: 100%;
  max-height: 54vh;
  font-size: 1.4rem;
  overflow: scroll;
  margin-bottom: 1.6rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.mapTable {
  width: 100%;
  height: 100%;
  overflow: scroll;
  border-collapse: collapse;
  position: relative;
}

.mapTr {
  width: 100%;

  &:nth-child(odd) {
    background-color: rgb(226, 222, 236);

    .mapRackID {
      background-color: rgb(226, 222, 236);
    }
  }
}

:global(.clickable) {
  // cursor: pointer;
}

.mapTh {
  padding: 4px 8px;
  position: sticky;
  top: 0;
  font-size: 1.4rem;
  z-index: 1;
  color: #fff;
  background-color: #002247;
}

.mapTh:first-child {
  left: 0;
  z-index: 30;
  padding: 4px 8px;
  width: 64px;
  color: transparent;
}

.mapTd {
  padding: 8px 4px;
  min-width: 200px;
}

.mapRackID {
  position: sticky;
  left: 0;
  padding-inline: 1rem;
  z-index: 15;
  background-color: #fff;
  min-width: fit-content !important;
  cursor: pointer;

  > div {
    position: sticky;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    border-radius: 4px;
  }

  :hover {
    background-color: #73959d !important;
    transition: 0.3s;
    border-radius: 4px;
  }
}

.activeLine {
  background-color: #73959d !important;
  transition: 0.3s;
  border-radius: 4px;
}

.mapCellContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mapFragmentMd {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2px;
  width: 100%;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
}

.mapLine {
  background-color: #466c77;
  text-align: center;
  padding: 0.8rem 0;
  height: 100%;
  transition: 0.3s;

  &.hide {
    background-color: transparent;
    color: transparent;
    padding: 0.8rem 0;
  }
}

.mapLine:hover {
  background-color: #2c4f57;
  cursor: pointer;
  transition: 0.3s;
}

.activeShelf {
  background-color: #2c4f57;
  cursor: pointer;
  transition: 0.3s;
}

.mapLine:first-child:not(.hide) {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.mapLine:last-child:not(.hide) {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.mapRackLocate {
  min-width: 60px;
  font-weight: 500;
  white-space: nowrap;
}

// Global styles module CSS

.h1 {
  font-size: 3rem;
  color: #003975;
  font-weight: 700;
}

.h2 {
  font-size: 2.5rem;
  color: #000000;
  font-weight: 600;
}

.modalContainer {
  background-color: #fff;
  padding: 2rem;
  border-radius: 4px;
  .title {
    font-weight: 700;
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
  .button {
    padding: 0.7rem 1rem;
    background-color: rgb(2, 132, 199);
    font-weight: 500;
    color: #fff;
    border-radius: 4px;
    width: 100px;
    transition: all 0.2s ease-in;
    &:hover {
      background-color: rgb(3, 102, 152);
    }
    &.red {
      background-color: #ff0000;
      &:hover {
        background-color: #c21a1a;
      }
    }

    &.border {
      background-color: #fff;
      border: 1px solid #c0c0c0;
      color: #002247;
      &:hover {
        border-color: #746b6b;
      }
    }
  }
}
.warningValidate {
  color: red;
}

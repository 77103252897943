.navBtn {
  transform: translateX(0);
  transition: all 0.1s ease-in-out;
  &.open {
    transform: translateX(60px);
  }
}
aside.sidebar {
  background-color: #000c19;
  width: 60px;
  position: fixed;
  height: 100%;
  font-size: 1rem;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  z-index: 100;
  top: 0;
  left: 0;
  transform: translateX(-60px);

  &.open {
    transform: translateX(0);
  }

  & > * {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media screen and (min-width: 768px) {
    transform: translateX(0);
  }

  .sidebar-item {
    cursor: pointer;
    position: relative;
    width: 100%;

    &.active {
      background-color: #004fa3;

      &::after {
        content: "";
        display: block;
        background-color: #57a8ff;
        position: absolute;
        width: 3px;
        height: 100%;
        top: 0;
        right: 0;
      }
    }
  }

  .brand {
    .img-wrap {
      background-color: #179d49;
    }
  }
}

.switch-warehouse {
  bottom: 0;
  position: absolute;
  width: 100%;

  button {
    background-color: #86ABD2;
    width: 45px ;
    height: 45px;
    min-width: 35px ;
    border-radius: 50%;
    padding: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }

}

.switchWMS-wrap {
  width: 184px;
  padding: 11px 0 0;

  .title {
    padding: 4px 21px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    border-bottom: 1px solid #e5e5e5;

  }

  .content {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    line-height: 1.5;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 0  6px 0;

    .item {
      cursor: pointer;

      &.active {
        color: #179d49;
      }

      :hover {
        background-color: #EDFAFC;
        color: #000;
        font-weight: 600;
     
      }

      p {
      padding: 6px 21px;

      }
    }


  }
}




.title {
  font-weight: 600;
}
.deliDate {
  font-size: 1.4rem;
}

.status {
  height: 50px;
  width: 50px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

html {
  margin: 0;
  padding: 0;
  line-height: 1.4;
  font-family: "Raleway", sans-serif;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: lining-nums;
}

body {
  background-color: #dadbec;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea:focus,
input:focus {
  outline: none;
}

input,
button {
  z-index: 10;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
  background-color: #656b8bc5;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

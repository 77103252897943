.packProductsWrap {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;

  table {
    width: 100%;
    border: 4px solid gray;
    border-spacing: 10px;
    border-collapse: separate;
    display: table;
  }

  thead {
    display: table;
  }
}

.body {
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bodyRightHeader {
  .title {
    margin-bottom: 0;
    font-weight: 500;
  }
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.header {
  font-size: 16px;
}

.totalOrder {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.totalOrderPickup {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.footer {
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .actionWrap {
    display: flex;
    flex-direction: row;
    .action {
      margin-left: 10px;
    }

    .cancel {
      color: #333;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }

    .confirm {
      color: #fff;
      background-color: #ff6a00;
      border: 1px solid #ff6a00;
      border-radius: 4px;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }

    .unConfirm {
      color: #fff;
      background-color: #bca79d;
      border: 1px solid #bca79d;
      border-radius: 4px;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: 600;
      cursor: not-allowed;
    }
  }
}

.checkbox {
  width: 100%;
  height: 22px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.numberHightline {
  color: #333;
  font-weight: 600;
}

.textAlert {
  color: #ff6a00;
  font-weight: 600;
}

.textSafetyWarning {
  color: #398aff;
  font-weight: 600;
}

.bodyLeftBody {
  height: 100%;
  max-height: 260px;
  overflow-y: scroll;
}

.bodyRightBody {
  height: 100%;
  max-height: 250px;
  overflow-y: scroll;
  display: block;
  width: 100%;
}

.td {
  max-width: 100% !important;
  width: 100% !important;
}

.sumItem {
  background-color: #fff;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: #2d85a0;
    color: #fff;
    box-shadow: 2px 2px 4px #757575;
  }
}

.title {
  font-size: 2rem;
  font-weight: 700;
  cursor: default;
}

.pkgContainer {
  padding: 1.6rem 0;
  max-height: 55vh;
  overflow-y: scroll;
  margin-bottom: 1.6rem;

  & > div {
    transition: all 0.2s ease-in-out;
  }

  & > div:hover {
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
  }
}

// Button get
.button {
  background-color: #315e8f;
  color: #fff;
  padding: 1rem 1.6rem;
  border-radius: 2px;
}

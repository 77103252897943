.container {
  position: relative;
  background-color: #1f3d5e;
  padding: 0.6rem 1.6rem;
  border-radius: 4px;
  box-shadow: 2px 2px 9px rgb(216, 216, 216);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  max-width: 300px;
  user-select: none;
  -webkit-user-select: none;
  color: #fff;
  &:hover {
    box-shadow: 2px 2px 12px rgb(135, 134, 134);
  }
}

.text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

.options {
  position: absolute;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #838385;
  width: 100%;
  z-index: 30;
  min-width: fit-content;
}

.option {
  border: none;
  border-radius: 0;
  position: relative;
  padding: 1rem 1.6rem;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  &:hover {
    background-color: rgb(212, 227, 228);
  }
}

.selectedOpt {
  background-color: rgb(212, 227, 228);
}

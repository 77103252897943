// Search box module

.input {
  padding: 10px 16px 10px 48px;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  margin-right: 1.6rem;
  width: 100%;
  text-overflow: ellipsis;

  &:focus {
    border: 1px solid #797979;
  }
}

.container {
  position: relative;
  background-color: #1f3d5e;
  padding: 1rem 1.6rem;
  border: 1px solid #1f3d5e;
  border-radius: 4px;
  box-shadow: 2px 2px 9px rgb(216, 216, 216);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  max-width: 32vw;
  &:hover {
    box-shadow: 2px 2px 12px rgb(135, 134, 134);
  }
}

.text {
  overflow: hidden;
  white-space: nowrap;
  color: #fff;
  min-width: fit-content;
  user-select: none;
  -webkit-user-select: none;
}

.options {
  position: absolute;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #838385;
  width: 100%;
  z-index: 30;
  min-width: fit-content;
  user-select: none;
  -webkit-user-select: none;
}

.option {
  border: none;
  border-radius: 0;
  position: relative;
  padding: 1rem 1.6rem;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  &:hover {
    background-color: rgb(203, 204, 206);
  }
}

.selectedOpt {
  background-color: rgb(139, 156, 175);
}

// Button get
.button {
  background-color: #315e8f;
  color: #fff;
  border-radius: 2px;
  margin-left: 1.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #1f3d5e;
  }

  width: 100%;
}

.container {
  width: 100%;
  background-color: #fff;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 2px 2px 9px #c5c5c5;
  .title {
    font-size: 2rem;
    font-weight: 700;
  }

  .rackTableWrap {
    width: 100%;
    table {
      width: 100%;

      * {
        transition: all 0.1s ease-in-out;
      }
      th,
      td {
        padding: 0.7rem 1rem;
        text-align: center;
      }
      thead {
        background-color: #002247;
        color: #fff;
      }
      .row {
        &:not(:last-child) {
          border-bottom: 1px solid #ecebeb;
        }

        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: rgb(230, 230, 251);
        }
      }
      .addLine {
        text-align: left;
        border-top: 1px solid #b3b2b2;
        border-bottom: 1px solid #b3b2b2;
        cursor: pointer;
        &:hover {
          background-color: rgb(72, 72, 111);
          color: #fff;
        }
      }
    }
  }
}

.rackPanel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  padding: 3rem 5rem;
  animation: modalCoverAni 0.3s;
  animation-direction: normal;
  opacity: 1;
  overflow-y: scroll;
}

.modalClose {
  animation: modalCoverAniClose 0.3s;
  animation-direction: normal;
  opacity: 0;
}

@keyframes modalCoverAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modalCoverAniClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.container {
  background-color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: clamp(2rem, 5vw, 5rem);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 2px 9px rgba(73, 73, 73, 0.504);
  overflow: scroll;

  img {
    cursor: pointer;
  }

  .title {
    font-size: 2.5rem;
    font-weight: 600;
  }
  .IDInp {
    max-width: 100%;
    width: 50px;
    border-bottom: 1px solid #002247;

    &.dark {
      background-color: #002247;
      border-bottom: 1px solid #fff;
    }

    &.light {
      background-color: #0a4789;
      border-bottom: 1px solid #fff;
    }
  }
  .fragment {
    position: relative;
    background-color: #002247;
    color: #fff;
    border-radius: 2px;
    padding: 0.5rem;
    text-align: center;
    width: 100%;
    min-width: 250px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    .fnBox {
      display: flex;
      transition: all 0.2s ease-in-out;
      opacity: 0;
    }
    &:hover .fnBox {
      transition: all 0.2s ease-in-out;
      opacity: 1;
    }
  }
  .shelf {
    position: relative;
    background-color: #0a4789;
    color: #fff;
    border-radius: 2px;
    padding: 0.5rem;
    text-align: center;
    width: 100%;
    min-width: 250px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    .fnBox {
      display: flex;
      transition: all 0.2s ease-in-out;
      opacity: 0;
    }
    &:hover .fnBox {
      transition: all 0.2s ease-in-out;
      opacity: 1;
    }
  }
}

.infoLabel {
  width: 150px;
  font-weight: 700;
}
.infoSubLabel {
  width: 150px;
  text-align: right;
  font-weight: 600;
}

.button {
  padding: 0.7rem 1rem;
  background-color: rgb(2, 132, 199);
  font-weight: 500;
  color: #fff;
  border-radius: 4px;
  width: 100px;
  transition: all 0.2s ease-in;
  &:hover {
    background-color: rgb(3, 102, 152);
  }
  &.red {
    background-color: #ff0000;
    &:hover {
      background-color: #c21a1a;
    }
  }

  &.border {
    background-color: #fff;
    border: 1px solid #c0c0c0;
    color: #002247;
    &:hover {
      border-color: #746b6b;
    }
  }
}
